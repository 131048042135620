<template>
    <div class="bg-white p-3">
      <h4>{{ $t('traffic.ManageIP') }}</h4>
      <hr>
      <div class="flex">
        <CInput class="colorwite" :placeholder="$t('Filter by Domain')" :label="$t('Domain')" v-model="filters.domain" />
        <CInput class="colorwite" :placeholder="$t('Filter by IP')" :label="$t('IP')" v-model="filters.ip" />
        <CInput class="colorwite" type="date" :label="$t('Date')" v-model="filters.date" @change="handleDateChange" />
        <a-button type="primary" class="filter" @click="applyFilters"> {{ $t('Filter') }} </a-button>
        <a-button type="default" class="padding filter" @click="clearFilters"> {{ $t('Clear Filters') }} </a-button>
      </div>
      <div class="d-flex justify-content-end">
        <div class="searchInput">
          <a-input-search :placeholder="$t('message.KeywordSearch')" size="large" v-model="searchKeyword"
            @search="searchData">
            <a-button slot="enterButton" icon="search" />
          </a-input-search>
        </div>
        <a-button type="primary" class="padding clear" @click="unbanSelected"> {{ $t('Unban') }} </a-button>
      </div>
      <a-table :columns="columns" :data-source="data" class="mt-3"
        :row-selection="{ selectedRowKeys: selectedKeys, onChange: onchange }">
      </a-table>
    </div>
  </template>
  
  <script>
  import axios from "@/plugins/axios.js"
  
  const columns = [
    {
      title: 'IP',
      dataIndex: 'ip'
    },
    {
      title: 'Domain',
      dataIndex: 'domain'
    },
    {
      title: 'Rule/Reason',
      dataIndex: 'reason'
    },
    {
      title: 'Banned Date',
      dataIndex: 'created'
    },
    {
      title: 'Banned Time',
      dataIndex: 'bantime'
    },
  ];
  
  export default {
    data() {
      return {
        data: [],
        searchKeyword: '',
        columns,
        filters: {
          domain: '',
          ip: '',
          date: null
        },
        selectedKeys: [],
        loading: false,
        originaldata: [] // Store original data
      };
    },
    created() {
      this.fetchDomainAttack();
    },
    methods: {
      applyFilters() {
        const { domain, ip, date } = this.filters;
  
        this.filteredData = this.originaldata.filter(item => {
          const domainMatches = !domain || item.domain.includes(domain);
          const ipMatches = !ip || item.ip.includes(ip);
          const dateMatches = !date || item.created === date;
          return domainMatches && ipMatches && dateMatches;
        });
        this.data = this.filteredData.length > 0 ? this.filteredData : [];
      },
      clearFilters() {
        this.filters.domain = '';
        this.filters.ip = '';
        this.filters.date = null;
        this.data = [...this.originaldata]; // Reset data to original
      },
      onchange(selectedRowKeys) {
        this.selectedKeys = selectedRowKeys;
      },
      fetchDomainAttack() {
        this.loading = true;
        axios.get('attack/decisions/')
          .then(response => {
            this.data = response.data.result;
            this.originaldata = [...this.data]; // Store original data
          })
          .catch(error => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      },
      unbanSelected() {
        const selectedDecisions = this.selectedKeys.map(key => 
          {
          return {
            id: this.data[key].type == 'cs' ? this.data[key].id : this.data[key].ip,
            type: this.data[key].type
          }
        });
        if (selectedDecisions.length === 0) {
          return;
        }
  
        axios.post('attack/decisions/', { decision_ids: selectedDecisions })
          .then(response => {
            console.log(response.data.message);
            let decisionIds = [];
            for (let i = 0; i < selectedDecisions.length; i++) {
              decisionIds.push(selectedDecisions[i].id);
            }
            this.selectedKeys = [];
            this.fetchDomainAttack();
          })
          .catch(error => {
            console.error("Error while attempting to unban:", error);
          });
  
      },
      handleDateChange(date) {
        console.log(date); // Log the selected date
      },
      searchData() {
        const keyword = this.searchKeyword.toLowerCase();
        this.data = this.originaldata.filter(item =>
          Object.values(item).join(' ').toLowerCase().includes(keyword)
        );
        console.clear()
        console.log(this.data);
      }
    }
  }
  </script>
  
  <style>
  .padding {
    margin-left: 10px;
  }
  
  .clear {
    background-color: green;
    margin-top: 5px;
  }
  
  .colorwite {
    color: black;
    margin-right: 10px;
  }
  
  .flex {
    display: flex;
  }
  
  .flexs {
    display: flex;
    justify-content: end;
  }
  
  .filter {
    margin-top: 30px;
  }
  
  .center {
    text-align: end;
  }
  </style>